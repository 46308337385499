// import './custom.scss'

import 'regenerator-runtime/runtime'
import * as THREE  from 'three';

import Scroll from './scroll.js';


import { PlaneGeometry } from 'three';

import { WebGLRenderer } from "three";

import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';

 


// import { EffectComposer } from 'three/examples/js/postprocessing/EffectComposer.js';
// import { RenderPass } from 'three/examples/js/postprocessing/RenderPass.js';
// import { BlurPass } from 'three/examples/js/postprocessing/BlurPass.js'; 

// import { EffectComposer } from 'three/examples/js/postprocessing/EffectComposer.js';
// import { RenderPass } from 'three/examples/js/postprocessing/RenderPass.js';
// import { GlitchPass } from 'three/examples/js/postprocessing/GlitchPass.js';
 
 
    // import LocomotiveScroll from 'locomotive-scroll';

  import { TweenMax  } from 'gsap';
  import { gsap } from 'gsap';
  import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
  import { ScrollTrigger } from 'gsap/ScrollTrigger';



  import { ScrollMagicPluginGsap } from 'scrollmagic-plugin-gsap';
  import ScrollMagic from 'scrollmagic';

  import  'scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators.js';
  //node_modules\scrollmagic\scrollmagic\uncompressed\plugins\debug.addIndicators.js

  gsap.registerPlugin(ScrollToPlugin, ScrollTrigger , ScrollMagicPluginGsap     );
  ScrollMagicPluginGsap(ScrollMagic, TweenMax   );


  let posScroll  = 0;


  let currentOutScroll ;

  let stopRender = false;


//console.log('hello');
 
"use strict";




var mobile = window.innerWidth <= 543;

// Canvas properties and configuration
var mousePosition = { x: 0.5, y: 0.5 };
var circlesPosition = { x: 0, y: 0, rotation: 0, ease: true };

var canvasProps = {
  fpsInterval: 1000 / 30,
  now: null,
  then: null,
  elapsed: null,
  stop: false
};

var orbWidth = document.querySelector('.intro-overlay').offsetWidth;
//console.log(orbWidth);

// if(!mobile) {
  // orbWidth *= window.devicePixelRatio;
// }

// //console.log(orbWidth);

 var outScroll;

var EasingFunctions = {
  /*
    t = time
    b = beginning value
    c = change in value
    d = duration
  */
  easeOutQuad: function (t, b, c, d) {
    return -c * (t /= d) * (t - 2) + b;
  }
}

class Sketch{
	constructor(options){ // options es un objeto y le pasaré variables al crear la clase
		this.time = 0;

    this.colores = [  0xDC7684 , 0xE4CA99 ,0xEAF2F4, 0x2D7F9D, 0xA4C9D7, 0xAEDDE0, 0xA1B6B4, 0xFBFBFB,0xECEDED , 0xC9D1D8, 0xB1BECD, 0xF9F2D4 , 0xABDBE3, 0xEEE9EC, 0xBEC7D0 ,0xEAE9E8, 0xF3ECE4, 0xE8CED8, 0xCCE0DB, 0xF0EFE4 , 0xFCDBC4, 0xFCECDC, 0xFBE4DC, 0xF6F0ED, 0xE0D8C6, 0xF5F3EC, 0xF8F7F4, 0xECE6F3 ]
 
		this.container = options.dom; 
		this.scene = new THREE.Scene();
		this.raycaster = new THREE.Raycaster();
		this.mouse = new THREE.Vector2();

    this.mousePre = new THREE.Vector2();

		this.width = this.container.offsetWidth;
		this.height = this.container.offsetHeight;
 
		this.distCamera = 600;

		this.camera = new THREE.PerspectiveCamera(70, this.width/this.height, 100, 1500);

		this.camera.position.z= this.distCamera; // Posicíon de la cámara en Z


    this.totalParticles = 50;

    this.total = 10 + Math.floor(Math.random()*10);

    if(window.innerWidth<768){ 
        this.totalParticles = 25;
        this.total = 10;
    }

		/*
		Voy a intentar que mi plano inicialmente mida 100x 100 para hacer las equivalencias
		espacio three espacio html
		Para eso hacemos trigonometría del triángulo rectángulo entre la altura del navegador /2 y  la camera.position.z . El arcotangente , me dará la mitad del ángulo, por eso multiplico x 2
		El ángulo viene en radianes y lo transformo a ángulos

		Con estas operaciones consigo un plano que mida lo mismo en unides 3DS que  en pantalla
		*/
		this.camera.fov = 2* Math.atan( (this.height/2) / this.distCamera ) * (180/ Math.PI) ;

	

		this.renderer = new THREE.WebGLRenderer({
			antialias:true,
			alpha:true
		}); // el alpha true para quitar el fondo negro
		//this.renderer.setSize(this.width , this.height)

		console.log(this.renderer);
		 
		this.container.appendChild(this.renderer.domElement)

		this.controls = new OrbitControls(this.camera, this.renderer.domElement)

    this.objects = [];


    this.createParticles();

    this.setPositions();
    this.resize();
    this.setupResize();
    this.addObjects();
    this.addLights();

    this.mouseMovements();

    this.scroll = new Scroll();

    outScroll = this.scroll;


    this.render();

 




	}

	setupResize(){

			window.addEventListener('resize', this.resize.bind(this))

	}

	resize(){

		this.width = this.container.offsetWidth;
		this.height = this.container.offsetHeight;
		this.renderer.setSize(this.width , this.height);
		this.camera.aspect = this.width/this.height;
		this.camera.updateProjectionMatrix();


	}
 
 
 
	setPositions(){
		this.objects.forEach(o=>{
		//	console.log(o);
			o.position.y  = o.top +  this.currentScroll*o.velY; // this.height/2 -  o.height/2; 
			//o.mesh.position.x = o.left  - this.width/2  +  o.width/2;
		})	

    if(this.particle){ 
        this.particle.position.y  = this.particle.top +  this.currentScroll*this.particle.velY;

        this.particle.rotation.y+=0.0003;

    }


     
    
	}
  ran255(){
    let tmp = Math.floor(Math.random()*255) + 1;
    return tmp;
  }

  addObject( obj) {

    console.log(this.width , this.height);
    //let x = Math.random()* this.width - this.width/2 ;
    let x = Math.random()* this.width/2  - this.width/5 ;
    let y  = Math.random()*this.height  - this.height/2;
    let z =  Math.random()*50;

    z*=2*(Math.round(Math.random()) - 0.5);

    let rotX = Math.random()*360;
    let rotY = Math.random()*360;
    let rotZ = Math.random()*360


    obj.position.x = x // * spread;
    obj.position.y = y // * spread;
    obj.position.y = z;


    obj.speedRotationX = Math.random();
    obj.speedRotationY = Math.random();
    obj.speedRotationZ = Math.random();

    obj.top = y;

    obj.velY = 0.15+Math.random()/10;

    obj.rotation.x = rotX;
    obj.rotation.y = rotY;
    obj.rotation.z = rotZ;

    this.scene.add(obj);
    this.objects.push(obj);
  }


  addWireGeometry(geometry){



    let materialMeshSt = new THREE.MeshStandardMaterial( {

      color: new THREE.Color().setHSL( Math.random(), 1, 0.75 ),
      roughness: 0.5,
      metalness: 0,
      flatShading: true

    } );


    let ranC = 'rgb('+this.ran255()+','+this.ran255()+','+this.ran255()+')';
 

    let mesh =new   THREE.Mesh(geometry , materialMeshSt);
   // mesh.material.depthTest = false;
   // mesh.material.opacity = 0.25;
   // mesh.material.transparent = true;
 

    this.addObject(  mesh);

    return mesh;
    
  }

  addLineGeometry(  geometry) {


 
    let materialMeshSt = new THREE.MeshStandardMaterial( {

      color: new THREE.Color().setHSL( Math.random(), 1, 0.75 ),
      roughness: 0.5,
      metalness: 0,
      flatShading: true

    } );

    let colRan = this.colores[Math.floor(Math.random()*this.colores.length)];


    materialMeshSt.color = new THREE.Color( colRan );
 
    let mesh =new   THREE.Mesh(geometry , materialMeshSt);
    // console.log('color');
    // console.log(colRan); 
    this.addObject(  mesh);

    return mesh;
  }

  hazCono(ra=50, alt=75, seg=20){
 
    this.addLineGeometry( new THREE.ConeGeometry(ra, alt, seg));
  }

  hazCubo(ancho=100, alto=100, depth=100){

    this.addLineGeometry( new THREE.BoxGeometry(ancho, alto, depth));
 
  }

  hazCilindro(radio=50, altura=75, segmentos=25){ 
    this.addWireGeometry( new THREE.CylinderGeometry(radio, radio,     altura, segmentos) ); 
  }

  hazPica(radio = 50){ 
    this.addLineGeometry( new THREE.OctahedronGeometry(radio ) );  
  }

  hazPiramide(radio = 50){
 
    this.addLineGeometry( new THREE.TetrahedronGeometry(radio ) ); 
  }

  hazEsfera(radio = 50){
    this.addLineGeometry( new THREE.SphereGeometry(radio ,32, 16 ) ); 
  }

  hazCapsula(radio =50 , altura = 100, capSeg = 20, altSeg = 10){
    this.addWireGeometry( new THREE.CapsuleGeometry(radio, altura ,capSeg, altSeg ) ); 
  }

  hazToro(radio=100, radioExtru=25, radialSeg=15, tubSeg=25){
    this.addLineGeometry( new THREE.TorusGeometry(radio, radioExtru ,radialSeg, tubSeg ) ); 
  }
  hazMedioToro(radio=100, radioExtru=25, radialSeg=15, tubSeg=25){
    this.addLineGeometry( new THREE.TorusGeometry(radio, radioExtru ,radialSeg, tubSeg, Math.PI ) ); 
  }

  hazIcosaedro(radio = 50){
    this.addLineGeometry( new THREE.IcosahedronGeometry(radio ) ); 
  }

  hazPlano(ancho=300, alto=300, segmentos=24){
    this.addWireGeometry( new THREE.PlaneGeometry(ancho, alto ,segmentos, segmentos ) ); 
  }

  variosPlanos(ancho=300, alto=300, segmentos=24, cuantos=3 , sep =25){
    let x = Math.random()* this.width - this.width/2 ;
    let y  = Math.random()*this.height  - this.height/2;
    let z =  Math.random()*50;

      
    let rotX = Math.random()*360;
    let rotY = Math.random()*360;
    let rotZ = Math.random()*360

    for(var p= 0; p<cuantos; p++){

      var geometry =  new THREE.PlaneGeometry(ancho, alto ,segmentos, segmentos ) ;

      let ranC = 'rgb('+this.ran255()+','+this.ran255()+','+this.ran255()+')';

      const material = new THREE.LineBasicMaterial({color: ranC, shading: THREE.FlatShading});
      const mesh = new THREE.LineSegments(new THREE.EdgesGeometry(geometry, 1), material);
  
      mesh.material.depthTest = false;
      mesh.material.opacity = 0.25;
      mesh.material.transparent = true;
  

      // console.log('mesh');
      // console.log(mesh);
      // console.log('mesh');


 
  
      mesh.position.x = x // * spread;
      mesh.position.y = y // * spread;

      mesh.position.y = y + p*sep;
  
      mesh.rotation.x = rotX;
      mesh.rotation.y = rotY;
      mesh.rotation.z = rotZ;
  
      this.scene.add(mesh);
      this.objects.push(mesh);

 

    }

  }

  createParticles(){


    this.particle = new THREE.Object3D(); 

    this.particle.velY = 0.025+Math.random()*0.05;

    this.particle.top = 0;

    this.scene.add(this.particle);
  
    var geometry = new THREE.TetrahedronGeometry(5, 0); 

    let colRan = this.colores[Math.floor(Math.random()*this.colores.length)];
  
    var material = new THREE.MeshPhongMaterial({
      color : new THREE.Color( colRan ),
      shading: THREE.FlatShading
    });


//      color:     'rgb('+this.ran255()+','+this.ran255()+','+this.ran255()+')' ,


 
  
    for (var i = 0; i < this.totalParticles; i++) {
      var mesh = new THREE.Mesh(geometry, material);
      mesh.position.set(Math.random() - 0.5, Math.random() - 0.5, Math.random() - 0.5).normalize();
      mesh.position.multiplyScalar(90 + (Math.random() * 700));
      mesh.rotation.set(Math.random() * 2, Math.random() * 2, Math.random() * 2);
      this.particle.add(mesh);
    }


  }

 
  addLights(){
    this.scene.add( new THREE.HemisphereLight( 0xaaaaaa, 0x444444 ) );

    // this.hemiLight = new THREE.HemisphereLight(0xffffff, 0xffffff, 0.6);
    // this.hemiLight.color.setHSL(0.6, 1, 0.5);
    // this.hemiLight.groundColor.setHSL(0.095, 1, 0.5);
    // this.hemiLight.position.set(0, 0, 0);
    // this.scene.add(this.hemiLight);


    this.light = new THREE.DirectionalLight( 0xffffff, 0.5 );
    this.light.position.set( 1, 1, 1 );
    this.scene.add( this.light );



    // var lights = [];
    // lights[0] = new THREE.DirectionalLight( 0xffffff, 1 );
    // lights[0].position.set( 1, 0, 0 );
    // lights[1] = new THREE.DirectionalLight( 0x11E8BB, 1 );
    // lights[1].position.set( 0.75, 1, 0.5 );
    // lights[2] = new THREE.DirectionalLight( 0x8200C9, 1 );
    // lights[2].position.set( -0.75, -1, 0.5 );
    // this.scene.add( lights[0] );
    // this.scene.add( nvm llights[1] );
    // this.scene.add( lights[2] );

  }

  eligeTipo( n ){

       // this.hazCubo();
       let tmpO;
       // this.hazCapsula(); //no funciona en linea
        
    switch(n){

      case 0:
        let ra = Math.random()*25 +25;
        let alto = 2*ra ;// + Math.random()*ra/2
        let seg= 50;
        //console.log('HAgo Cilindro');
        tmpO = this.hazCilindro(ra, alto, seg)

      break;

      case 1:
        let prof;
        let ancho = alto = prof = Math.random()*25 +25;
        //console.log('HAgo Cubo');
        
       tmpO = this.hazCubo(ancho, alto, prof)
      break;

      case 2:
          ra = Math.random()*25 +25;
          //console.log('HAgo Pirámide');
       tmpO = this.hazPiramide(ra);
      break;

      case 3:
          ra = Math.random()*25 +25;
       tmpO = this.hazIcosaedro(ra);
      break;

      case 4:
          ra = Math.random()*25 +25;
          alto = 1.75*ra // ra/2 + Math.random()*ra/2
          seg= 20;
          //console.log('HAgo Cono');
       tmpO = this.hazCono(ra, alto, seg);
      break;

      case 5:
         ancho=150 + Math.random()*150;
          alto=ancho;
          let segmentos=24;
          let cuantos=3 
          let sep =25;
          //console.log('HAgo Planos');
        tmpO = this.variosPlanos(ancho, alto, segmentos, cuantos, sep);
      break;

      case 6:
          ra = Math.random()*50 +50;
        let raExtru =  10+  Math.random()*25
        let radialSeg= Math.random()*10 + 25;
        let tuboSeg= Math.random()*15 + 50;
        //console.log('HAgo Toro');
        tmpO = this.hazToro(ra, raExtru, radialSeg, tuboSeg);
      break;

      case 7:
          ra = Math.random()*25 +25;
          //console.log('HAgo Pica');
        tmpO = this.hazPica(ra);
      break;
    }

  }

	addObjects(){

    
    this.numFiguras = 8;
 
    for(var o=0; o<this.total;o++){

      let sel = Math.floor(Math.random()*this.numFiguras);

      this.eligeTipo(sel); 

    }

    


	}

  mouseMovements(){		

	
    console.log('this.mouseMovements();')

      window.addEventListener( 'mousemove', (event)=>{
          this.mouse.x = ( event.clientX / this.width ) * 2 - 1;
          this.mouse.y = - ( event.clientY / this.height ) * 2 + 1;


          this.mousePre = this.mouse;



          //RAYCASTER

          // // update the picking ray with the camera and mouse position
          // this.raycaster.setFromCamera( this.mouse, this.camera );

          // // calculate objects intersecting the picking ray
          // const intersects = this.raycaster.intersectObjects( this.scene.children );

          // if(intersects.length>0){
          //   //console.log(intersects[0]);
          //   let obj = intersects[0].object;
          //    //obj.material.uniforms.hover.value = intersects[0].uv; 
          //    console.log(obj);
          // }


      }, false );





}

 
	render(){
		this.time += 0.05;

    this.scroll.render();   

    currentOutScroll =  this.currentScroll = this.scroll.scrollToRender; //el scroll desde scroll.js
 

    this.setPositions();

 
      // this.camera.position.x  = (0-this.mouse.x)*10 ;
      // this.camera.position.y  = (0-this.mouse.y)*10 ;

 

    //if(this.gira){



      if(window.innerWidth > 768 ){

        this.giraTutto();
      }
      
    //}


    

     

		//this.mesh.rotation.x = this.time/2000;
		//this.mesh.rotation.y = this.time/1000;
 
		//this.material.uniforms.time.value = this.time;
		this.renderer.render(this.scene, this.camera);
 
		window.requestAnimationFrame(this.render.bind(this))
	}



giraTutto(){


  this.objects.forEach(o=>{
		//	console.log(o);

   // if(o.speedRotationY){

        o.rotation.y  += o.speedRotationY*0.003 + this.scroll.speedTarget/50  ; 
        
        o.rotation.x  += o.speedRotationY*0.003 + this.scroll.speedTarget/50 ; 
        // this.height/2 -  o.height/2; 
        //o.mesh.position.x = o.left  - this.width/2  +  o.width/2;

  //  }

		})	


}

}

  new Sketch({
    dom:document.getElementById('intro-overlay')
  });


 
 
 
 
    if(!mobile) {
      window.addEventListener('mousemove', event => {
        mousePosition.x = event.clientX / window.innerWidth;
        mousePosition.y = event.clientY / window.innerHeight;
      }, false);
    }
    else {
      window.addEventListener('mousemove', event => {
        mousePosition.x = event.clientX / window.innerWidth;
        mousePosition.y = event.clientY / window.innerHeight;
      }, false);
  
      window.addEventListener('touchmove', event => {
        mousePosition.x = event.touches[0].clientX / window.innerWidth;
        mousePosition.y = event.touches[0].clientY / window.innerHeight;
      }, false);
    } 


  //   const scroll = new LocomotiveScroll({
  //     el: document.querySelector('[data-scroll-container]'),
  //     smooth: true
  // });


 
  hazScrollMagic();


  let controlMagic;

  let arrSecciones;
  let alturaSecciones;
  
function hazScrollMagic(){
 
  let imgCap = document.getElementById('img_cap_waste');

  if(imgCap){


      let dimenCap = imgCap.getBoundingClientRect();
      dimenCap = {width:dimenCap.width, height:dimenCap.height};

      imgCap.style.width = dimenCap.width+'px';
      imgCap.style.height = dimenCap.height+'px';



  }


  controlMagic = new ScrollMagic.Controller();

 
let activo;

 arrSecciones =    document.querySelectorAll('.seccion');
 

let arrScenes = [];

//console.log(arrSecciones);

let logo = document.getElementById('cont_logo');

//arrScenes
  Array.prototype.forEach.call(arrSecciones, function(el) {

    let tmpScene = new ScrollMagic.Scene({
      triggerElement: el,
      duration:window.innerHeight,
      triggerHook:0.9,
      //offset:   -window.innerHeight/2, // start this scene after scrolling for 50px
  })
  //.setPin(el)
 // .setClassToggle(el, 'op_0') 
 //.addIndicators() 
  .addTo(controlMagic)  
  .on('enter leave', function (e) {
  // $("#state").text(e.type == "enter" ? "inside" : "outside");
  // //console.log('Enter / leave');
 ////console.log(e );

    if(e.type==='enter'){
      // let fraseVida = document.getElementById('frase_vida');
      // fraseVida.classList.remove('op_0');

      // //console.log(el );
     //  //console.log('tmpClase');
      let tmpClase = el.getAttribute('data-forlogo');

    //  console.log('tmpClase'); 
    //  console.log(tmpClase); 
    //  console.log('logo'); 
    //  console.log(logo);


        logo.classList.remove(...logo.classList);

        logo.classList.add(tmpClase);
 

       
       if(e.scrollDirection=='FORWARD'){


          if(activo){
          //  activo.classList.add('op_0');
          }

       }

        el.classList.add('primer_plano');
      //  el.classList.remove('op_0');




        activo = el;
        // //console.log('activo');
        // //console.log(activo);
    }

    if(e.type==='leave'){
      // let fraseVida = document.getElementById('frase_vida');
      // fraseVida.classList.remove('op_0');

    //   //console.log(el );

     //  logo.classList.remove(...logo.classList);

       logo.classList.add('pos_rel');

        el.classList.remove('primer_plano');
      //  el.classList.add('op_0');
    }



  });

  arrScenes.push(tmpScene);

  });
 
}


//muevo lento

let items_lentos = document.getElementsByClassName('mueve_scroll_lento');


function getDocHeight() {
  var D = document;
  return Math.max(
      D.body.scrollHeight, D.documentElement.scrollHeight,
      D.body.offsetHeight, D.documentElement.offsetHeight,
      D.body.clientHeight, D.documentElement.clientHeight
  )
}


function amountscrolled(){
  var winheight= window.innerHeight || (document.documentElement || document.body).clientHeight
  var docheight = getDocHeight()
  var scrollTop = window.pageYOffset || (document.documentElement || document.body.parentNode || document.body).scrollTop
  var trackLength = docheight - winheight
  var pctScrolled = Math.floor(scrollTop/trackLength * 100) // gets percentage scrolled (ie: 80 or NaN if tracklength == 0)
 // //console.log(pctScrolled + '% scrolled');

 posScroll = pctScrolled;


  if(items_lentos){

      Array.prototype.forEach.call(items_lentos, function(el) {
        // Do stuff here
        var tmpPor = el.dataset.delay;
      //  //console.log(el+' '+(pctScrolled/tmpPor)+' / '+tmpPor);
        el.style.transform = 'translateY('+pctScrolled/tmpPor*-1+'px)';

      });
  }




}

window.addEventListener('scroll', function(){

  if(stopRender){
    amountscrolled();
  }
}, false)


//JQUERY

/*
$(".page-header ul a").on("click", function (e) {
  e.preventDefault();
  const href = $(this).attr("href");
  $("html, body").animate({ scrollTop: $(href).offset().top }, 800);
});

*/


jQuery(document).ready(function(){

 

  jQuery('.link_go').on('click', function(ev){

    ev.preventDefault();
    let goTo = jQuery(ev.currentTarget).attr('href');

    goTo = jQuery(goTo).offset().top - 250;

    stopRender = true;

    //alert(goTo);


    jQuery("html, body").stop().animate({ scrollTop: goTo }, 200 , function(){

      stopRender = false;

    });


    // gsap.to(window, {duration: 0.7,   scrollTo:goTo  , delay:0 , onComplete:function(){ stopRender = false; }  }   );


    if(menu_prin){

        if(  menu_prin.classList.contains('menu_abierto')){
          
        //  botMovil.click();

        menu_prin.classList.toggle('menu_abierto');

        }


    }




  })


})


window.addEventListener('resize', generalResize);
let linea_larga = document.getElementById('linea_larga');

let linea_up = document.getElementById('linea_up');
let linea_up_final = document.getElementById('linea_up_final');
let caja_linea = document.getElementById('caja_linea');

let pos_caja_linea;

let punto_final  = document.getElementById('punto_final');
let pos_ptoFinal;


let trapecio_final  = document.getElementById('trapecio_final');
let pos_trapecioFinal;

let punto_inicio =   document.getElementById('punto_inicio');;
let pos_ptoInicio;


function generalResize(){

  pos_ptoInicio = punto_inicio.getBoundingClientRect(); 

  pos_ptoInicio = pos_ptoInicio.top; 

  alturaSecciones = 0;
  let cont =0;
  Array.prototype.forEach.call(arrSecciones, function(el) {
    let tmpAlt = el.getBoundingClientRect();

   // if((cont<arrSecciones.length-2) ){

     //Así evito cerrar en la caja de elecciones 
     //if((cont<arrSecciones.length-3) ){
      if((cont<2) ){
 

        alturaSecciones += tmpAlt.height;      
    }



    cont++;

  });

  console.log('alturaSecciones ' + alturaSecciones);

  console.log('pos_ptoInicio' , pos_ptoInicio);

  let  winScrollTop = jQuery(window).scrollTop()
  console.log(winScrollTop);


  linea_larga.style.height = (alturaSecciones - pos_ptoInicio - winScrollTop + 30)+'px'; 

  pos_caja_linea = caja_linea.getBoundingClientRect();

  pos_caja_linea = pos_caja_linea.top + pos_caja_linea.height;

   

  trapecio_final  = document.getElementById('trapecio_final');

  trapecio_final = trapecio_final.getBoundingClientRect();

  pos_trapecioFinal = trapecio_final.y + trapecio_final.height/2 ;; 

  let  dif_altura = pos_trapecioFinal - pos_caja_linea  ; 

  linea_up.style.height = (dif_altura-12) +"px"; 


  punto_final  = document.getElementById('punto_final'); 
  pos_ptoFinal = punto_final.y + punto_final.height/2 ;
 

  let dif_alturaFinal = -1*currentOutScroll + pos_ptoFinal - (pos_trapecioFinal +  trapecio_final.height );

  console.log('dif_alturaFinal '+ dif_alturaFinal); 

  linea_up_final.style.height = dif_alturaFinal+"px";

  console.log('coloco lineas');


if(window.innerWidth < 640 ){

  //canvasMovilResize();
}


//console.log('generalResize');


posicionoTrapecios();  

console.log('GENERAL RESIZE');

outScroll.setSize();

}

let arrTrapCloned =[];
  var arrTrapeRef = document.getElementsByClassName('trapecio_ref');
function colocoTrapecios(){

  //console.log('coloco trapecios');

  // console.log('arrTrapeRef');
  // console.log(arrTrapeRef);
  var trapAlfa = document.getElementById('trapecio_alfa');

  let count=0;
  Array.prototype.forEach.call(arrTrapeRef, (t)=>{

    //console.log(t); 
    let posTrap = t.getBoundingClientRect(); 
    //console.log(posTrap);

    let tmpClone = trapAlfa.cloneNode( true );
    tmpClone.setAttribute('id', 'trap_'+count );
    tmpClone.setAttribute('class', 'trap_help' );

    tmpClone.style.top = posTrap.top +'px';
    //tmpClone.style.left = posTrap.left +'px';
    // tmpClone.style.left =  '0px';

    t.dataset.cloned = 'trap_'+count ;

    arrTrapCloned.push(tmpClone);

    document.querySelector('#coloca_trapecios').appendChild( tmpClone );


    count++;

  })

  console.log('arrTrapCloned');
  console.log(arrTrapCloned);

}


function posicionoTrapecios(){


  let count =0 ;
  Array.prototype.forEach.call(arrTrapeRef, (t)=>{

    console.log('posTrap >>> ', count); 
    let posTrap = t.getBoundingClientRect(); 
    console.log(posTrap); 
    let tmpClone = document.getElementById( t.getAttribute('data-cloned'));
    // tmpClone.setAttribute('id', 'trap_'+count );
    // tmpClone.setAttribute('class', 'trap_help' ); 
    if(tmpClone){
      console.log('tmpClone');
      console.log(tmpClone);
      tmpClone.style.top = currentOutScroll + posTrap.top +'px';

    }

    //tmpClone.style.left = posTrap.left +'px';
    // tmpClone.style.left =  '0px';
 

    count++;

  })

}

/*
// Create a clone of element with id ddl_1:
let clone = document.querySelector('#ddl_1').cloneNode( true );

// Change the id attribute of the newly created element:
clone.setAttribute( 'id', newId );

// Append the newly created element on element p 
document.querySelector('p').appendChild( clone );
*/

generalResize();

let botMovil = document.getElementById('bot_menu');

let menu_prin = document.getElementById('menu_prin');

botMovil.addEventListener('click', clickMovil);

function clickMovil(ev){
  ev.preventDefault();

  menu_prin.classList.toggle('menu_abierto');
 
}

var botCas = document.getElementById('pon_cas');
var botVal = document.getElementById('pon_val');

var bodyDoc = document.getElementsByTagName('body');
bodyDoc = bodyDoc[0];
var miHtml = document.getElementsByTagName('html');
miHtml = miHtml[0]

botCas.addEventListener('click', (e)=>{

  e.preventDefault();

  bodyDoc.classList.remove('language_val');
  bodyDoc.classList.add('language_cas');


  miHtml.lang = 'es';

 jQuery("html, body").animate({ scrollTop: 0 }, 0 , function(){

    if(orb){

      orb.resize();  
        
  }
      generalResize();


 });


 if( menu_prin.classList.contains('menu_abierto')){
      
  botMovil.click();

}



});


botVal.addEventListener('click', (e)=>{

  e.preventDefault();
  bodyDoc.classList.remove('language_cas');
  bodyDoc.classList.add('language_val');



  miHtml.lang = 'ca';

 jQuery("html, body").animate({ scrollTop: 0 }, 0 , function(){

  if(orb){

     orb.resize();  

  }

    generalResize();


 });

 if( menu_prin.classList.contains('menu_abierto')){
      
  botMovil.click();

}




});


window.onload = function(){
  generalResize();
  jQuery("html, body").animate({ scrollTop: 0 }, 0 , function(){

 
    generalResize();


 });
  //console.log('resizeo!!!!!');


 // initSmooth();
 
 colocoTrapecios(); 

var colocotime = setTimeout(posicionoTrapecios, 1000);
 var colocotime2 = setTimeout(posicionoTrapecios, 2000);
 var colocotime3 = setTimeout(posicionoTrapecios, 3000);
  
}


let actTitPanel;
let actPanel;

var arrAcordeones = document.getElementsByClassName('tit_acordeon');
//var arrPaneles = document.getElementsByClassName('expli_acordeon');


Array.prototype.forEach.call(arrAcordeones, (item)=>{
  // console.log(item);
  // console.log(item.nextSibling);
  item.addEventListener('click' , (ev)=>{
    ev.preventDefault();
  
    let tmpItem = ev.currentTarget; 
    let tmpPanel = tmpItem.nextElementSibling;


    console.log('CLICK ACORDEON');
    console.log(tmpItem);
    console.log('CLICK PANEL');
    console.log(tmpPanel)
 

    if(actTitPanel && (tmpItem != actTitPanel)){
      console.log('quito cruz'); 
      actTitPanel.classList.remove('cruz');

    }
    if(actPanel && (tmpPanel != actPanel)){
      console.log('quito d-flex');
      jQuery(actPanel).slideUp(400, function(){
 
 
        generalResize();
    
       })

      //actPanel.classList.remove('d-flex');
      
    }


    // console.log('tmpItem');
    // console.log(tmpItem); 
    // console.log('tmpPanel');
    // console.log(tmpPanel); 
 //   tmpPanel.classList.toggle('d-flex'); 
    tmpItem.classList.toggle('cruz');

    jQuery(tmpPanel).slideToggle(400, function(){
 
 
     generalResize();
     
 
    })

    actTitPanel = tmpItem;
    actPanel = tmpPanel;


  })
})